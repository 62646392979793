import Container from '@components/container';
import classNames from 'classnames';
import { ContainerSize, FocalPoint, HeroStatic, HeroVideo } from '@lib/types';
import Image from '@components/image';
import { useRef } from 'react';
import { useShowAnimation } from 'hook/useShowAnimation';
import Vimeo from '@components/vimeo';

type HeroSectionProps = {
  data: HeroStatic | HeroVideo;
};

const focalpoints: Record<FocalPoint, string> = {
  bottom: 'object-bottom',
  center: 'object-center',
  left: 'object-left',
  leftBottom: 'object-left-bottom',
  leftTop: 'object-left-top',
  right: 'object-right',
  rightBottom: 'object-right-bottom',
  rightTop: 'object-right-top',
  top: 'object-top',
  custom: '',
};

export default function HeroSection({
  data: {
    title,
    cover,
    backgroundFocalpoint,
    customFocalpoint,
    vimeoUrl,
    overline,
    videoStartAtSecond,
  },
}: HeroSectionProps) {
  const ref = useRef<HTMLIFrameElement | null>(null);
  const showAnimation = useShowAnimation(ref);

  return (
    <Container size={ContainerSize.full}>
      <div className="relative" ref={ref}>
        <div className="relative overflow-hidden rounded-xl md:rounded-3xl hero-height">
          <div className="absolute inset-0">
            {cover && backgroundFocalpoint && (
              <Image
                className={classNames(
                  `transition duration-[3000ms] h-full w-full object-cover ${focalpoints[backgroundFocalpoint]}`,
                  {
                    'opacity-0 scale-110': !showAnimation,
                    'opacity-100 scale-100': showAnimation,
                  },
                )}
                style={
                  backgroundFocalpoint === 'custom'
                    ? { objectPosition: customFocalpoint }
                    : {}
                }
                src={cover}
                height={cover.height}
                width={cover.width}
                alt={cover.alternativeText}
                sizes="100vw"
                loading="eager"
              />
            )}
            {vimeoUrl && (
              <div className="w-[100vw] h-[56.25vw] min-w-[177.77vh] min-h-[100vh] absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
                <Vimeo
                  url={vimeoUrl}
                  preset="no_controls_autoplay"
                  start={videoStartAtSecond}
                />
              </div>
            )}
            {
              <div
                className={classNames(
                  'absolute inset-0 bg-blue-400 transition-opacity duration-[2000ms]',
                  {
                    'opacity-0': !showAnimation,
                    'opacity-20': showAnimation,
                  },
                )}
              />
            }
          </div>
        </div>
        <div
          className={classNames(
            'absolute inset-x-0 bottom-0 -mx-6 md:-mx-12 transition-opacity delay-700 duration-1000',
            {
              'opacity-0': !showAnimation,
              'opacity-100': showAnimation,
            },
          )}>
          <div className="px-6 md:px-12 grid grid-cols-12 gap-1.5 md:gap-6 pb-16 md:pb-24 m-auto max-w-screen-3xl">
            <h1 className="col-start-2 lg:col-start-4 col-span-10 lg:col-span-8">
              {overline && (
                <span className="block text-white font-bold text-20 leading-125 md:text-35 md:leading-130">
                  {overline}
                </span>
              )}
              <span className="block text-white text-40 leading-120 font-bold md:text-50 lg:text-55 xl:text-85 lg:leading-110">
                {title}
              </span>
            </h1>
          </div>
        </div>
      </div>
    </Container>
  );
}
