import { buildImageUrl, buildSrcSet } from '@lib/image';
import { ImageData } from '@lib/types';
import { ImgHTMLAttributes, forwardRef } from 'react';

export type ImageSectionProps = Omit<
  ImgHTMLAttributes<HTMLImageElement>,
  'src'
> & {
  src: ImageData;
};

export default forwardRef<HTMLImageElement, ImageSectionProps>(function Image(
  props,
  ref,
) {
  const { alt, loading, src, ...rest } = props;

  return (
    <img
      ref={ref}
      srcSet={buildSrcSet(src)}
      src={buildImageUrl(src.url)}
      {...rest}
      alt={alt || ''}
      decoding="async"
      loading={loading || 'lazy'}
    />
  );
});
